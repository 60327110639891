import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import { Object } from 'core-js';
import { rel } from '@ifeng-fe/ui_rel';

const month = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

class MonthNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        config: PropTypes.object,
    };

    state = {
        currentMonth: false,
        currentYear: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.config.showYear !== prevState.currentYear) {
            return {
                currentMonth: nextProps.config.initMonth,
                currentYear: nextProps.config.showYear,
            };
        }

        return null;
    }

    select = month => {
        const { currentMonth } = this.state;

        if (currentMonth === month) return;

        this.setState({
            currentMonth: month,
        });
    };

    render() {
        const { content, config } = this.props;
        const { currentMonth } = this.state;

        const __currentMonth = currentMonth || config.initMonth;

        const dataMonth = Object.keys(content);
        const cardData = content[__currentMonth] || [];

        const monthDom = month.map((item, i) => {
            let __className = {};

            const canUse = dataMonth.includes(item) && content[item] && content[item].length > 0;

            if (canUse) {
                // 该月份有数据

                if (__currentMonth === item) {
                    __className = style.preBlueBg;
                }
            } else {
                __className = style.gray;
            }

            const __clickConfig = canUse ? { onMouseOver: () => this.select(item) } : {};

            return (
                <li className={__className} key={i} {...__clickConfig}>
                    <div className={style.pre}>
                        <span>{item}</span>月
                    </div>
                </li>
            );
        });

        const cardCell = cardData.map((item, i) => {
            return (
                <li className={style.monthlist01} key={`cardcell-${i}`}>
                    <a href={item.url} target="_blank" rel={rel}>
                        <div className={style.imgWrap}>
                            <img
                                src={
                                    item.thumbnail ||
                                    '//d.ifengimg.com/w750_h376/y3.ifengimg.com/a/2014_22/5e372c71473c6bc.jpg'
                                }
                                alt={item.title}
                            />
                        </div>
                        <p className={style.p01}>Vol.{item.serial}</p>
                        <p className={style.p02}>{item.title}</p>
                    </a>
                </li>
            );
        });

        return config.showYear ? (
            <div className={style.navlistcontent}>
                <ul className={style.navmonth}>{monthDom}</ul>
                <div className={style.navmonthlist}>
                    <ul className={style.navmonthlistUL}>{cardCell}</ul>
                </div>
            </div>
        ) : (
            ''
        );
    }
}

export default MonthNews;
