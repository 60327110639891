import { jsonp } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// 获取文章评论数
// 新时代获取文章评论数
export const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

/**
 * xinjinping 分页
 * @param {string} dataKey 数据key
 */
export const getXiNewsList = async ({ start, len }) => {
    return await jsonp(`${apiBaseUrl}/xijinping/index/getCustomNewsTfList/${start}/${len}/getCustomData`, {
        jsonpCallback: 'getCustomData',
        cache: 120,
    });
};

/**
 * xuanzhang2020 分页
 * @param {string} dataKey 数据key
 */
export const getXuanzhangNewsList = async ({ start, len }) => {
    return await jsonp(`${apiBaseUrl}/xuanzhan/index/getCustomNewsTfList/${start}/${len}/getCustomData`, {
        jsonpCallback: 'getCustomData',
        cache: 120,
    });
};

// 在人间

// 点击更多，获取信息流
export const getLivingColumnData = async (start, end) => {
    const data = await jsonp(`${apiBaseUrl}/living/index/getLivingColumnData_v2/${start}/${end}/getLivingColumnData`, {
        jsonpCallback: 'getLivingColumnData',
    });

    return data;
};

// 地球青年图鉴栏目

// 点击更多，获取信息流
export const getGlobalyouthColumnData = async (currentLen, size = 10) => {
    const data = await jsonp(
        `${apiBaseUrl}/globalyouth/index/getGlobalyouthColumnData_v2/${currentLen}/${size}/getGlobalyouthColumnData`,
        {
            jsonpCallback: 'getGlobalyouthColumnData',
        },
    );

    return data;
};
