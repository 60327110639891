import React from 'react';
import style from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    /**
     * 渲染组件
     */
    render() {
        return <div className={style.top_logo} />;
    }
}

export default errorBoundary(Logo);
