import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import CardStream from '../../components/cardStream';
import TopNews from './topNews';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { isEnd } = content;

        return (
            <div className={style.content}>
                <TopNews content={content} />
                <CardStream isEnd={isEnd} content={content.newsstream} />
            </div>
        );
    }
}

export default Content;
