import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import { rel } from '@ifeng-fe/ui_rel';
import MonthNews from './monthNews';

const num = 6;

class TopNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        currentYear: false,
        leftYear: this.props.content.years[0],
        rightYear: this.props.content.years[5],
        leftSize: 0,
    };

    move = type => {
        const { leftYear, rightYear, leftSize } = this.state;

        if (type === 'pre') {
            this.setState({
                leftYear: leftYear + 1,
                leftSize: leftSize + 110,
                rightYear: rightYear + 1,
            });
        } else {
            this.setState({
                leftYear: leftYear - 1,
                leftSize: leftSize - 110,
                rightYear: rightYear - 1,
            });
        }
    };

    select = selectYear => {
        const { currentYear } = this.state;

        this.setState({ currentYear: selectYear === currentYear ? false : selectYear });
    };

    render() {
        const { content } = this.props;

        const { years } = content;

        const { currentYear, leftYear, rightYear, leftSize } = this.state;

        const monthData = content[currentYear] || {};

        const yearsDom = years.map((item, index) => {
            const result =
                currentYear && item === currentYear ? (
                    <span className={style.navlistyear} key={index} onClick={() => this.select(item)}>
                        <span style={{ color: 'rgb(0, 160, 231)' }}>{item}</span>
                        <span className={style.img2} style={{ color: 'rgb(0, 160, 231)' }} />
                    </span>
                ) : (
                    <span className={style.navlistyear} key={index} onClick={() => this.select(item)}>
                        <span>{item}</span>
                        <span className={style.img} />
                    </span>
                );

            return result;
        });

        const yearsDomWidth = 110 * content.years.length;

        const preButton = () => {
            const result =
                leftYear === years[0] ? (
                    <div className={`${style.buttonPrev} ${style.buttonDisabled}`} />
                ) : (
                    <div className={style.buttonPrev} onClick={() => this.move('pre')} />
                );

            return years.length > num ? result : '';
        };

        const nextButton = () => {
            const result =
                rightYear === [...years].pop() ? (
                    <div className={`${style.buttonNext} ${style.buttonDisabled}`} />
                ) : (
                    <div className={style.buttonNext} onClick={() => this.move('next')} />
                );

            return years.length > num ? result : '';
        };

        const __currentMonth = Object.keys(monthData).length > 0 ? Object.keys(monthData)[0] : false;

        return (
            <div className={style.nav}>
                <div className={style.navlist}>
                    <div className={style.navlistbg}>
                        <div
                            className={style.sliderWrap}
                            style={{
                                width: `${yearsDomWidth}px`,
                                marginLeft: `${leftSize}px`,
                                transitionDuration: '300ms',
                            }}>
                            {yearsDom}
                        </div>
                    </div>
                    {preButton()}
                    {nextButton()}
                    <div className={style.hr_month} />
                    <MonthNews
                        content={monthData}
                        config={{ showYear: currentYear || false, initMonth: __currentMonth }}
                    />
                </div>
            </div>
        );
    }
}

export default TopNews;
